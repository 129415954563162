<template>
  <div class="WJgroupHonor ">
    <headert></headert>
    <el-row class="content ">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="top-banner">
          <img src="../../assets/image/banner/concept.png" alt="">
        </div>
      </el-col>
    </el-row>
    <div class="paddingb150">
      <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb' />
      <el-row class="  " type="flex" justify="center" align='center'>
        <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
          <div class="padding-tb70">
            <div class="WJgroup-titleBox flex">
              <p class="WJgroup-title">{{$t('school.title')}}</p>
            </div>
          </div>
          <div class="WJgroup_teamTips flex justify_center ">
            <span class="WJgroup_teamTl">“</span>
            <div>
              <p> {{$t('school.slogan')}}</p>
            </div>
            <span class="WJgroup_teamTr">”</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="cadreSchoolBox " type="flex" justify="center" align='center'>
        <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
          <h6 class="flex justify_center">
            <span :class="lang=='zh'?'fontZH':'fontEN'">{{$t('school.desc1')}}</span>
            <span :class="lang=='zh'?'fontZH':'fontEN'"> {{$t('school.desc2')}}</span>
            <span :class="lang=='zh'?'fontZH':'fontEN'"> {{$t('school.desc3')}} </span>
            <span :class="lang=='zh'?'fontZH':'fontEN'">{{$t('school.desc4')}}</span>
            <span :class="lang=='zh'?'fontZH':'fontEN'">{{$t('school.desc5')}}</span></h6>
        </el-col>
      </el-row>
      <el-row class="cadreSchoolmsg " type="flex" justify="center" align='center'>
        <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
          <div v-html="news.content"></div>
        </el-col>
      </el-row>
      <el-row class=" " type="flex" justify="center" align='center'>
        <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
      <div class="cadreSchoolActivity" >
        <div class="cadreSchoolTitle">
          {{$t('school.classShow')}}
        </div>
        <el-row type="flex" justify="center" align='center'>
          <el-col :xs="23" :sm="23" :md="23" :lg="12" :xl="12">
            <el-carousel height="540px">
              <el-carousel-item v-for="(item,index) in activityRecommendList" :key="index">
                <img style="width: 100%;height: 100%;" :src="item.litpic" alt="" @click="$router.push({path:'/InformationDetail?type=1&id='+item.aid})">
              </el-carousel-item>
            </el-carousel>
          </el-col>
          <el-col :xs="23" :sm="23" :md="23" :lg="12" :xl="12">
            <ul class="cSActivityul">
              <li v-for="(item,index) in activityRecommendList" :key="index" class="flex justify_between" @click="$router.push({path:'/InformationDetail?type=139&id='+item.aid})">
                <p>{{item.title}}</p>
                <span class="cstime">{{item.add_time}}</span>
                <i class="el-icon-right csicon"></i>
              </li>
              <div class="flex justify_end" @click="getMore">
                <p class="cSActivitymore">{{$t('school.more')}} <i class="el-icon-right"></i></p>
              </div>
            </ul>
          </el-col>
        </el-row>
      </div>
      </el-col>

      </el-row>
      <div class="cadreSchoolstudent">
        <div class="cadreSchoolTitle">
          {{$t('school.student')}}
        </div>
        <el-row class="cadreSchool-row" type="flex" justify="center" align='center'>
          <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
            <ul class='cSstudent flex justify_between align-start'>
              <li v-for="(item,index) in studentList" :key="index">
                <img :src="item.litpic" alt="">
                <p>{{item.title}}</p>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
  <!--    <div class="cadreSchoolActivity">
          <div class="cadreSchoolTitle">
            课程展示
          </div>
          <el-row type="flex" justify="center" align='center'>
            <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
              <div class="curriculumBox">
                <el-carousel height="540px">
                  <el-carousel-item >
                    <div class="curriculumImg">
                      <img src="../../assets/image/Industry/cadre10.png" alt="">
                    </div>
                  </el-carousel-item>
                </el-carousel>

                </ul>
              </div>
            </el-col>
          </el-row>

        </div> -->
    <!--  <div class="cadreSchoolActivity">
        <div class="cadreSchoolTitle">
          课程展示
        </div>
        <el-row type="flex" justify="center" align='center'>
          <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
            <div class="curriculumBox">
              <div class="curriculumImg">
                <img src="../../assets/image/Industry/cadre10.png" alt="">
              </div>
              <ul class="curriculumUl flex">
                <li v-for="(item,index) in learnList" :key="index" class="flex flex_column justify_center">
                  <img src="../../assets/image/Industry/cadre5.png" alt="">
                  <p>{{item.title}}</p>
                </li>

              </ul>
            </div>
          </el-col>
        </el-row>

      </div> -->
    </div>
    <el-row>

      <el-col>
        <footerb></footerb>
      </el-col>

    </el-row>
    <!-- 底部 -->

  </div>
</template>

<script>
  export default {
    name: "WJgroupIntroduce",
    components: {},
    data() {
      return {
        menuActiveIndex: '/personnelCadreSchool',
        menu: {
          name: this.$t('menu.recruit'),
          path: '/personneltalentConcept',
          list: [{
            name: this.$t('menu.talented'),
            path: '/personneltalentConcept',
          }, {
            name: this.$t('menu.school'),
            path: '/personnelCadreSchool',
          }, {
            name:  this.$t('menu.joinUs'),
            path: '/personnelJoinUs',
          }, ]
        },
        breadcrumb: [{
          name: this.$t('menu.recruit'),
          path: '/personneltalentConcept',
        }, {
          name:this.$t('menu.school'),
          path: '/personnelCadreSchool',
        }],

        teamIndex: 1,
        news: {},
        activityList: [],
        studentList: [],
        learnList: [],
        activityRecommendList: [],
        typeInfo:{}
      };
    },
    computed: {
      lang(){
        return window.localStorage.getItem('language')
      },
    },
    created() {
      this.getData();
    },
    methods: {
      getMore(){
        this.$router.push('/moreSchool')
      },
        // moreSchool
      getData(){
        let api='?m=api&c=v1.Api&a=index&_ajax=1';
        this.$request.post(api,{
          apiArclist_1:`ekey=1&typeid=139&limit=6&noflag=c&addfields=typename_en`,//课程展示
          apiArclist_2:`ekey=2&typeid=140&limit=5&addfields=litpic_en,title_en`,//优秀学员
          apiArclist_3:`ekey=3&typeid=141&limit=4`,//课程展示
          apiArclist_4:`ekey=4&typeid=136&limit=1&addfields=content_en&notypeid=139,140,141`,//干部学校
          apiArclist_5:`ekey=5&typeid=139&flag=c&limit=6,title_en`,//典礼活动获取栏目推荐文章
        }).then(res=>{
          this.typeInfo=this.$utils.translate(res.apiArclist[1].arctype)
          console.log(this.typeInfo)
          this.activityList =this.$utils.translate(res.apiArclist[1].data);

          this.studentList =this.$utils.translate(res.apiArclist[2].data)
          this.learnList =this.$utils.translate(res.apiArclist[3].data)
          this.activityRecommendList = this.$utils.translate(res.apiArclist[5].data)

          if (res.apiArclist[4].data && res.apiArclist[4].data.length > 0) {
            this.news = this.$utils.translate(res.apiArclist[4].data[0])
          }
        })
      },
      choseTeam(e) {
        this.teamIndex = e
      },
      setActiveItem(index) {
        this.$refs.Carousel.setActiveItem(index)
      },
      carouselChange(e) {

        this.yearIndex = e
      },
      choseYears(index) {
        this.yearIndex = index
      },
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>
<style lang="less" scoped>
  .WJgroup_teamTips div {
    width: 81%;
  }

  .WJgroup_teamTips p {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: left;
    color: #8F6B4B;
    margin-bottom: 20px;

  }

  .WJgroup_teamTips span {
    font-family: 'PangMenZhengDao';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 73px;
    /* identical to box height */
    color: #A4825F;
  }

  .WJgroup_teamTl {
    margin-bottom: 102px;
    margin-right: 24px;
  }

  .WJgroup_teamTr {
    margin-left: 24px;
    margin-bottom: -110px;
  }

  .cadreSchoolBox {
    background: url('../../assets/image/Industry/cadre1.png') no-repeat;
    background-size: 100%;
    height: 258px;
    margin-top: 130px;
  }

  .cadreSchoolBox h6 {
    width: 100%;
    height: 100%;
    background: rgba(65, 45, 28, 0.33);
    text-align: center;
  }

  .cadreSchoolBox h6 span {
    font-family: 'PangMenZhengDao';
    font-style: normal;
    font-weight: 400;

    line-height: 57px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 0 10px;
  }
  .fontZH{
    font-size: 40px;
  }
  .fontEN{
    font-size: 26px;
  }

  .cadreSchoolmsg {
    background: #F5F5F5;
    padding: 20px 0 80px;
  }

  .cadreSchoolmsg h6 {
    margin-top: 61px;
    // font-family: 'OPPOSans-M';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 38px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #A4825F;
  }

  .cadreSchoolmsg p {
    // font-family: 'OPPOSans';
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 30p !important;
    letter-spacing: 0.5px !important;
    text-transform: uppercase !important;
    color: #1F2D39 !important;
    margin-top: 20px !important;
  }

  .cadreSchoolActivity {
    padding-top: 60px;
    padding-bottom: 66px;
  }

  .cadreSchoolTitle {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 38px;
    letter-spacing: 0.5px;
    // text-transform: uppercase;
    color: #1F2D39;
    text-align: center;
    margin-bottom: 108px;
    position: relative;
  }

  .cadreSchoolTitle::before {
    content: '';
    position: absolute;
    bottom: -14px;
    left: 50%;
    margin-left: -16px;
    width: 32px;
    height: 3px;
    background: #1F2D39;
  }

  .cSActivityul {
    padding: 0 40px;
  }

  .cSActivityul li {
    cursor: pointer;
    height: 79px;
    border-bottom: 1px dashed #a0a0a0;
  }

  .cSActivityul li:hover {
    p {
      color: #8F6B4B;
    }

    span {
      display: none;
    }

    .csicon {
      display: block;
    }
  }

  .cSActivityul .csicon {
    display: none;
    color: #8F6B4B;
    font-size: 26px;
    margin-right: 10px;
  }

  .cSActivityul li:nth-child(6) {
    border-bottom: 0 !important;
  }

  .cSActivityul li p {
    font-size: 18px;
    line-height: 79px;
    color: #333;
    // font-family: 'OPPOSans-M';
  }

  .cSActivityul li span {
    font-size: 16px;
    color: #898989;
    // font-family: 'OPPOSans';
    line-height: 79px;
  }

  .cSActivitymore {
    padding: 0 30px;
    border: 1px solid #333;
    line-height: 30px;
    margin-top: 20px;
    cursor: pointer;
    color: #333;
    font-size: 16px;
    transition: 0.5s;
  }

  .cSActivitymore:hover {
    background-color: #D5B88E;
    color: #FFFFFF;
    border: 1px solid #D5B88E;

  }


  .cadreSchoolstudent {
    padding: 60px 0;
    background: #F5F5F5;
  }

  .cSstudent li {
    width: 19.5%;
    position: relative;
    padding-top: 28.5%;
    overflow: hidden;
  }

  .cSstudent li:hover img {
    transform: scale(1.1);
  }

  .cSstudent li:nth-child(2n-1) {
    margin-top: 202px;
  }

  .cSstudent li img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: 1s;
  }

  .cSstudent li p {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 72px;
    background: rgba(0, 0, 0, 0.5);
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 72px;
    letter-spacing: 0.5px;
    text-align: center;
    color: #FFFFFF;

  }

  .curriculumImg {
    // width: 59%;
    position: relative;
    // padding-top: 40.5%;
    overflow: hidden;
    width: 100%;
padding-top: 50.5%;
  }

  .curriculumImg img {
    width: 100%;
    // height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .curriculumBox {
    position: relative;
    width: 100%
  }

  .curriculumUl {
    position: absolute;
    top: 50%;
    margin-top: -100px;
    right: 0;
  }

  .curriculumUl li {
    width: 206px;
    height: 200px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;

  }

  .curriculumUl li img {
    width: 60px;
    height: 60px;
  }

  .curriculumUl li p {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.5px;
    color: #1F2D39;
    margin-top: 22px;
  }
  @media screen and (min-width:320px) and (max-width:767px) {
    .WJgroupHonor .WJgroup_teamTips p{
      font-size: 39px;
      line-height: 52px;
    }
    .cadreSchoolBox{
      background-size: cover;
      .fontZH{
        font-size: 43px;
      }
    }
    .cadreSchoolTitle{
      font-size: 39px;
    }
     .el-row--flex{
      //display: block!important;
      flex-wrap: wrap;
    }
    .el-col-xs-23{
      flex-shrink: 0;
    }
  .WJgroupHonor  ::v-deep .el-carousel__container{
      height: 500px!important;
    }
  .cSActivityul{
    padding: 0;
    li{
      padding:20px 0;
      p{
        font-size: 34px;

      }
      span{
        font-size: 34px;
      }
    }
  }
    .cSActivitymore{
      line-height: 52px;
      font-size: 34px;
    }
    .WJgroup_teamTl {
      margin-bottom: 202px;
      margin-right: 24px;
    }

    .WJgroup_teamTr {
      margin-left: 24px;
      margin-bottom: -240px;
    }
  }
</style>
